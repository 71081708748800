import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import '../css/coursecard.css'
import '../css/contactus.css'
import SEO from "../components/seo"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wpPage

    return (
      <Layout>
        <SEO title={currentPage.title} description="" />
        <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
